<template>
  <div
    class="mats-listing__wrapper color-listing__wrapper"
    style="margin-top: 2%;"
  >
    <div style="width: 100%;  padding-top: 16px; margin-top: 0px">
      <table
        class="team_table"
        :class="{'button--disabled': getArticleOrders.length == 0}"
      >
        <thead>
          <tr>
            <th>
              {{ $t('ArticleName') }}
            </th>
            <th>
              {{ $t("PickupDate") }}
            </th>
            <th>
              {{ $t("Created") }}
            </th>
            <th>
              {{ $t("Edit") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="orderId != ''">
          <tr
            v-for="(item, index) in filteredArticleOrders"
            :key="index"
            :style="classNameCh === item.orderNumber ? '' : ''"
            @click="matProps(item)"
          >
            <td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ fromatDate(item.pickUpDate) }}
            </td>
            <td>
              {{ fromatDate(item.created) }}
            </td>
            <td
              v-b-toggle.edit-order-item
              @click="getEditOrderItemm(item.orderItemId); getEditOrderItemMaterialVariant(item.orderItemId); articleForTitle(item.articleNumber)"
            >
              <button
                class="button"
              >
                {{ $t('Edit') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-pagination
        v-if="getTotalItemsForOrderItems > 15"
        v-model="page"
        :total-rows="getTotalItemsForOrderItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        size="md"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <AdminTeamModal
      :selected-team="selectedTeam"
      @assign="addTeamToArticle"
    />
    <AdminStateModal
      :selected-state="selectedState"
      @state="newStateAdmin"
    />
    <SellArticleModal
      :order-number="orderItemId"
      :article-name="articleee"
      @sellDresss="sellProductInStock"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <EditOrderItemModal
      :article-number="articleNumber"
      @editOrderItemm="edittOrder"
    />
    <EditMaterialsAllOrders
      :article-number="articleNumber"
      @onCancelToggle="onCancelToggle"
      @editOrderItemm="edittOrder"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import AddTeamModal from '@/components/orders/modals/AddTeamModal.vue';
import { mapActions, mapGetters } from 'vuex';
import SellArticleModal from '@/components/orders/modals/SellArticleOrderModal.vue'
import EditOrderItemModal from '@/components/orders/modals/EditOrderItemModal.vue'
import { client } from '@/domainConfig'
import moment from 'moment'
import EditMaterialsAllOrders from './EditMaterialsAllOrders.vue'


export default {
  components: {
    // AdminTeamModal,
    SellArticleModal,
    EditOrderItemModal,
    // AdminStateModal,
    EditMaterialsAllOrders,
    // Spinner,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderId', 'createOrder', 'orderStateOfOrder', 'orderItemIds'],
  data() {
    return {
      isSidebarOpen: false,

      initialId: '',
      materialPropId: '',
      client: client.clientName,
      status: true,
      classNameCh: '',
      articleee: null,
      searchTerm: '',
      rows: [],
      articleNumber: null,
      orderItemId: null,
      materialObject: {},
      editMat: {},
      selectedOrderItem: '',
      selectedTeam: '',
      selectedState: '',
      ord: '',
      page: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapGetters(['getArticleOrders', 'getCurrentPageForCreatedOrders', 'getTotalItemsForOrderItems', 'getCurrentPageForOrderItems', 'getIsLoading', 'getLoggedInUser', 'getArticleOrders', 'getOrderItemStates']),
    rowsEr() {
      return this.rows.filter((post) => {
        return post.orderNumber.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
    filteredArticleOrders() {
      return this.getArticleOrders.filter((item) => { return item.stateDtos[item.state].stateName !== 'Delivered' });
    },
    showCurrentTeam() {
      return this.filteredArticleOrders.some((item) => { return item.stateDtos[item.state].stateName !== 'Cancelled' });
    },
    getOrderItemStateName() {
      return (stateValue) => {
        const stage = this.stages.find((stageee) => { return stageee.value === stateValue });
        return stage ? stage.name : '';
      };
    },
  },
  watch: {
    initialId() {
      this.immediateId(this.initialId);
    },
    page(value) {
      // this.changeLoadingtoTrue(false)

      this.loadCreatedOrdersByOrderNumber({
        id: this.orderId,
        pageNumber: value,
        pageSize: this.pageSize,
        successCallback: () => {
          // this.changeLoadingtoTrue(true)
        },
      });
    },
  },
  methods: {
    ...mapActions(['getMaterialVariantsToMDCPagination', 'adminOrdersOverview', 'editVariantLength', 'edit_OrderItem', 'teamNames', 'loadCreatedOrdersByOrderNumber', 'assignOrderItemToTeamLeader', 'loadCreatedOreders', 'changeLoadingtoTrue', 'resetCreatedOrdersByOrderNumber', 'getTeamNamesPagination', 'sell_productInStock', 'get_edit_OrderItem', 'edit_OrderItem_MaterialVariants', 'readOrderItems', 'editTeamAdmin', 'orderItemStates', 'editStateAdmin', 'getOrderItemsManagementBy', 'getLanguage']),
    edittOrder(value) {
      this.editVariantLength({
        orderItemId: this.orderItemId,
        object: value,
        successCallback: () => {
        },
      })
    },
    fromatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    returnState(orderItem, selectState) {
      this.orderItemStates()
      this.selectedOrderItem = orderItem
      this.selectedState = selectState
    },
    getOrderItem(orderItem, teamName) {
      this.teamNames({
        pageNumber: 1,

        pageSize: 15,

      });
      console.log(orderItem)
      this.selectedOrderItem = orderItem
      this.selectedTeam = teamName
    },
    articleForTitle(value) {
      this.articleNumber = value
    },
    async addTeamToArticle(obj) {
      const objToSend = {
        orderNumber: this.orderId,
        orderItemId: this.selectedOrderItem,
        newTeamNameId: obj.value,
        notes: null,
      };
      await this.editTeamAdmin({
        object: objToSend,
        successCallback: () => {
          this.readOrderItems({
            orderNumber: this.orderId,
            orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
          })
          this.adminOrdersOverview({
            salesType: 'Product',
            pageNumber: this.page,
            pageSize: this.pageSize,
            orderNumber: null,
            clientId: null,
            orderType: null,
            orderState: null,
          })
        },
      });
    },
    async newStateAdmin(obj) {
      const objToSend = {
        orderNumber: this.orderId,
        orderItemId: this.selectedOrderItem,
        newState: obj.teamName,
        newTeamNameId: null,
        notes: obj.notes,
      };
      await this.editStateAdmin({
        object: objToSend,
        successCallback: () => {
          this.readOrderItems({
            orderNumber: this.orderId,
            orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
          })
          this.adminOrdersOverview({
            pageNumber: this.page,
            pageSize: this.pageSize,
            salesType: 'Product',
            orderNumber: null,
            clientId: null,
            orderType: null,
            orderState: null,
          })
        },
      });
    },
    sellArticleTeamName() {
      this.getTeamNamesPagination({
        pageNumber: 1,
        pageSize: this.pageSize,
      })
    },
    getEditOrderItemm(value) {
      this.orderItemId = value
      this.isSidebarOpen = true
      // this.get_edit_OrderItem(value)
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    getEditOrderItemMaterialVariant(value) {
      this.edit_OrderItem_MaterialVariants(value)
    },
    orderItemID(value) {
      this.orderItemId = value
    },

    sellProductInStock(value) {
      console.log('dbb', value)
      this.sell_productInStock({
        orderItemId: value.orderItemId,
        articleNumber: value.articleNumber,
        assignmentTeamNameId: value.assignmentTeamNameId,
        successCallback: () => {
          this.loadCreatedOrdersByOrderNumber({
            id: this.orderId,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
          this.loadCreatedOreders({
            pageNumber: this.getCurrentPageForCreatedOrders,
            pageSize: this.pageSize,
            orderNumber: null,
          })
        },
      })
    },

    editMaterial(item) {
      this.editMat = item;
      this.$bvModal.show('modal-edit-dress');
    },

    articleNAME(value) {
      this.articleee = value
    },

    materialsData(obj) {
      this.materialObject = obj;
      this.addTeamToArticle();
    },
    // materialsData

    immediateId(e) {
      this.$emit('click', e);
    },
    matProps(article) {
      this.materialPropId = article.materialId;
      this.classNameCh = article.colorName;
    },
  },
};
</script>

<style scoped lang="scss">
.editButton {
  height: 30px;
}
.button{
  height: 34px;
  margin-bottom:0px !important
}
.button:hover{
  height: 34px;
  margin-bottom:0px !important
}
.team_table td {
  padding: 12px 9px !important;
}

.color-listing__wrapper {
  margin-left: 20px;
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}

tr:last-child {
  border-bottom: none !important;
}
</style>
